.box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.text-secondary {
  color: #999999;
}

.font-monospace {
  font-family: "Consolas", "Lucida Console", "Courier New", Courier, monospace;
}
